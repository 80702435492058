// sass-lint:disable no-color-hex

@import '~sg-bootstrap/scss/standard/colors';

// Breakpoints
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

$screen-xs-min: $screen-xs !default;
$screen-sm-min: $screen-sm !default;
$screen-md-min: $screen-md !default;
$screen-lg-min: $screen-lg !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

// $screen-phone:        $screen-xs-min !default;
// $screen-tablet:       $screen-sm-min !default;
// $screen-desktop:      $screen-md-min !default;
// $screen-lg-desktop:   $screen-lg-min !default;

// Box shallows
$flag-shallow: transparentize($black, .8);

// Tag Selector
$tag-selector-text-color-hover: #e6e6e6;
$tag-selector-background-color-hover: #5e5e5e;

$header-height: 92px !default;
$footer-spacing-top: 100px !default;
$footer-sm-height: 101px !default;
$footer-height: 41px !default;
