// sass-lint:disable no-important

$spacing-directions: (
  t: "top",
  r: "right",
  b: "bottom",
  l: "left"
) !default;

$spacing-mappings: (
  none: 0,
  auto: "auto",
  0: 0,
  1: .25rem,
  2: .5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
) !default;

@each $shortcut, $direction in $spacing-directions {
  @each $option, $value in $spacing-mappings {
    .m#{$shortcut}-#{$option} {
      margin-#{$direction}: $value !important;
    }
    .p#{$shortcut}-#{$option} {
      padding-#{$direction}: $value !important;
    }
  }
}