@import 'variables';
@import 'utils/float';
@import 'utils/spacings';
@import 'includes/sg-bootstrap-fix';
@import 'includes/section';
@import 'includes/container';
@import 'includes/mobile-zoom';

html {
  position: relative;
  min-height: 100%;
}

sgwt-mini-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  .navbar-fixed-top {
    position: relative;
  }
}

main {
  position: relative;
  margin-top: $header-height;
  margin-bottom: $footer-spacing-top + $footer-sm-height;

  @media (min-width: $screen-sm-min) {
    margin-bottom: $footer-spacing-top + $footer-height;
  }
}

.footerbar {
  position: absolute;
  bottom: 0;
  width: 100%;
}

[hidden] {
  display: none !important;   // sass-lint:disable-line no-important
}

.flag-with-shadow {
  border-radius: 3px;
  box-shadow: 1px 1px 3px $flag-shallow;
  width: 70px;
}

.btn {
  span {
    padding-left: 5px;
  }
}

// Custom colors (for Guideline viewer & editor)
.text-blue {
	color: #529aff;
}

.bg-blue {
	background-color: #529aff;
}

.text-red {
	color: #ff563c;
}

.bg-red {
	background-color: #ff563c;
}

.text-green {
	color: #00d750;
}

.bg-green {
	background-color: #00d750;
}

.text-orange {
	color: #f98d00;
}

.bg-orange {
	background-color: #f98d00;
}

.text-black {
	color: #000000;
}

.bg-black {
	background-color: #000000;
}

.text-white {
	color: #ffffff;
}

.bg-white {
	background-color: #ffffff;
}