@mixin btn-icon {
  & i {
    line-height: 32px;
  }
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: bold;
  font-weight: 700;
  src: url('/assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
}

.input-group-btn {
  .btn-icon {
    @include btn-icon;
  }
}

.btn-icon {
  @include btn-icon;
}

.breadcrumb {
  padding-right: 0;
  padding-left: 0;
}

.switch {
  margin-top: 2px;

  .switch-container {
    label {
      margin-bottom: 0;
    }
  }
}

.media {
  display: flex;
  overflow: initial;

  .media-left,
  .media-right {
    display: flex;
    flex: none;
    align-items: flex-start;

    &.media-middle {
      align-items: center;
    }

    &.media-bottom {
      align-items: flex-end;
    }
  }

  .media-body {
    display: block;
    flex: 1 0;
    min-width: 0;
    overflow: initial;
  }
}

textarea {
  resize: vertical;
}

.form-group {
  .control-label {
    font-weight: bold;
  }
}
