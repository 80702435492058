@mixin heading-first-child {
  &:first-child {
    margin-top: 0;
  }
}

.container {
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    @include heading-first-child;
  }
}
